<div *ngIf="variant" class="row variant jg-{{colIndex+1}}">
    <div *ngIf="(colIndex+1)%3 == 2" class="col-md-60 p-1 spacer compare-background-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}">
    </div>
    <div id="placeholder-col-{{(colIndex+1)%3}}" *ngIf="(colIndex+1)%3 != 2" class="col-md-60 p-1">
    </div>
    <div #row class="col-md-60 product-description compare-background-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}">
        <div class="borderline">
            <img *ngIf="variant.images[0]" src="{{ variant.images[0].url }}" (load)="onImgLoadResize()" alt="vorschlag" style="height:150px;"/>
            <img *ngIf="!variant.images[0]" src="assets/img/product-{{colIndex+1}}.png" alt="vorschlag" style="height:150px;" />
        </div>
    </div>
    <div #row class="col-md-60 product-description pt-0 compare-background-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}">
        <h4>{{ variant.productName }}</h4>
        <div class="d-block d-md-none">Max. Ladeleistung</div>
        <p class="power">{{ variant.chargerConnections }} x {{ variant.maximumChargingCapacity.toLocaleString('de-DE') }} kW</p>
    </div>
    <div #row id="accessories-col-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}" class="col-md-60 compare-details compare-background-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}">
        <ng-container *ngIf="variant.accessories && variant.accessories.length > 0; then accessoryContent else accessoryEmpty"></ng-container>
        <ng-template #accessoryContent>
            <div class="d-block d-md-none">Zubehör</div>
            <ul>
                <li *ngFor="let accessory of variant.accessories"
                    class="accessories">
                    {{ accessory.name }}
                </li>
            </ul>
        </ng-template>
        <ng-template #accessoryEmpty>
            <div class="d-block empty-field">ohne Zubehör</div>
        </ng-template>
    </div>
    <div #row id="download-col-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}" class="col-md-60 compare-details compare-background-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}" style="min-height: 3rem !important;">
        <ng-container *ngIf="variant.documents && variant.documents.length > 0; then documentsContent else documentsEmpty"></ng-container>
        <ng-template #documentsContent>
        <div class="d-block d-md-none">Weitere Produktinformationen</div>
        <a *ngFor="let document of variant.documents"
           target="_blank"
           href="{{ document.url }}"
           class="document-download"
           >
            {{ document.description }}
        </a>
        </ng-template>
        <ng-template #documentsEmpty>
            <div class="d-block empty-field">keine Dokumente</div>
        </ng-template>
    </div>
    <div #row id="price-col-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}" class="col-md-60 compare-details compare-background-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}">
        <div class="d-block d-md-none">Angebotspreis</div>
        <h4>{{ variant.formatPrice(variant.getTotalPrice()) }}</h4>
    </div>
    <div #row id="button-col-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}}" class="col-md-60 compare-details compare-background-{{(colIndex+1)%3 == 0 ? 3 : (colIndex+1)%3}} button-container">
        <a routerLink="/contact" class="btn btn-primary" (click)="setVariantOnService()">Angebot anfordern</a>
    </div>
</div>
<div *ngIf="!variant">
    <alert type="info">Keine passenden Produkte gefunden</alert>
</div>
