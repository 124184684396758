import {AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Variant} from '../Variant';
import {SuggestionComponent} from '../suggestion.component';
import {ValueCounterGroup} from './ValueCounterGroup';
import {VariantToFormServiceService} from '../../../variant-to-form-service.service';
import {SuggestionService} from "../suggestion.service";
import {FinalSelectionService} from "../../../final-selection.service";
import {Selection} from "../../Selection";

// https://scotch.io/tutorials/responsive-equal-height-with-angular-directive

@Component({
    selector: 'app-variant',
    templateUrl: './variant.component.html',
    styleUrls: ['./variant.component.scss'],
    host: {
        '(window:resize)': 'onResize()',
    }
})
export class VariantComponent implements AfterViewInit, OnInit {
    @Input() variant: Variant;
    @Input() colIndex: number;

    @Input() parent: SuggestionComponent;

    private _maxHeights: ValueCounterGroup;

    public maxHeightAccessory: number|null;
    public maxHeightInfo: number|null;
    public maxHeightPrice: number|null;

    @ViewChildren('row') rows: QueryList<ElementRef>;

    private _formService: VariantToFormServiceService;

    constructor(formService: VariantToFormServiceService, private _suggestionService: SuggestionService,
                private _finalSelectionService: FinalSelectionService) {
        this._formService = formService;
    }

    ngOnInit(): void {
    }

    private _setHeight(index, value) {
        this.rows.toArray()[index].nativeElement.style.height = value + 'px';
    }

    @Input('maxHeights')
    set maxHeights(value: ValueCounterGroup) {
        this._maxHeights = value;
        for (let i = 0; i < this._maxHeights.length; i++) {
            this._maxHeights.get(i).addCallback((val) => {
                this._setHeight(i, val);
            });
        }
    }

    ngAfterViewInit(): void {
        if (window.innerWidth > 768) {
            this.rows.forEach((val, i) => {
                this._maxHeights.add(i, this.colIndex, val.nativeElement.scrollHeight);
            });
        }
    }

    onResize() {
        this.rows.forEach((val) => {
            val.nativeElement.style.height = 'auto';
        });
        this.ngAfterViewInit();
    }

    setVariantOnService() {
        this._suggestionService.getSuggestion(this.parent.selection, this.variant.variantId).subscribe(() => {});
        this._formService.variant = this.variant;
        this._finalSelectionService.selection = this.parent.selection;
    }

    public onImgLoadResize() {
      this.onResize();
    }

    forceHeightUpdate() {
      this.rows.forEach((val, i) => {
        this._maxHeights.forceRecalculation();
      });
    }
}
