<div class="col-60">
  <h1>
    Ladesäulenkonfigurator
    <button *ngIf="isDebug()" class="btn btn-primary text-uppercase col-10" type="submit" (click)="update()">Debug</button>
  </h1>
  <p>Bitte füllen Sie folgende Felder aus:</p>
  <div [hidden]="loading">
    <div class="row">
      <div class="col-60 single-line">
        <div class="row">
          <div class="col-60 col-lg-25 d-flex label-container">
            <label>Ladeleistung<span class="required">*</span></label>
          </div>
          <div class="col-60 col-lg-35 d-flex">
            <ng-select [items]="chargingCapacities"
                       bindLabel="name"
                       (change)="onSelectChange('chargingCapacities')"
                       [(ngModel)]="selection.chargingCapacity">
            </ng-select>
            <app-tooltip
                    [message]="labelInfos.charging_capacity"
                    [large]="true"
            ></app-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-60 single-line">
        <div class="row">
          <div class="col-60 col-lg-25 d-flex label-container">
            <label>Anzahl der Ladepunkte<span class="required">*</span></label>
          </div>
          <div class="col-60 col-lg-35 d-flex">
            <ng-select [items]="chargerConnections"
                       bindLabel="name"
                       (change)="onSelectChange('chargerConnections')"
                       [(ngModel)]="selection.chargerConnections">
            </ng-select>
            <app-tooltip
              [message]="labelInfos.charger_connections"
              [large]="true"
            ></app-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-60 single-line">
        <div class="row">
          <div class="col-60 col-lg-25 d-flex label-container">
            <label>Installationsart<span class="required">*</span></label>
          </div>
          <div class="col-60 col-lg-35 d-flex">
            <ng-select [items]="installationTypes"
                       bindLabel="name"
                       [placeholder]="!selection.chargingCapacity ? '' : 'Auswählen...'"
                       [disabled]="!selection.chargingCapacity"
                       [(ngModel)]="selection.installationType"
                       (change)="onSelectChange('installationType')"
            >
            </ng-select>
            <app-tooltip
                    [message]="labelInfos.installation_type"
                    [large]="true"
            ></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-60 single-line">
        <div class="row">
          <div class="col-60 col-lg-25 d-flex label-container">
            <label>Zubehör</label>
          </div>
          <div class="col-60 col-lg-35 d-flex">
            <!-- todo multiple must be changed to true! -->
            <ng-select [items]="accessories"
                       #accessoriesSelect
                       [multiple]="true"
                       [searchable]="false"
                       [closeOnSelect]="false"
                       bindLabel="name"
                       [placeholder]="!selection.installationType ? '' : 'Auswählen...'"
                       [disabled]="!selection.installationType"
                       (change)="onSelectChange('accessories')"
                       [(ngModel)]="selection.accessories">
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of (items ? items.slice(0,1): [])">
                  <span *ngIf="items.length == 1" class="ng-value-label">{{item.name}}</span>
                  <span *ngIf="items.length >= 2" class="ng-value-label"> {{items.length}} ausgewählt</span>
                  <span class="ng-value-icon right" (click)="resetSelection('accessoriesSelect')" aria-hidden="true">×</span>
                </div>
              </ng-template>
            </ng-select>
            <app-tooltip
                    [message]="labelInfos.accessories"
                    [large]="true"
            ></app-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-60 single-line">
        <div class="row">
          <div class="col-60 col-lg-25 d-flex label-container">
            <label>Autorisierung</label>
          </div>
          <div class="col-60 col-lg-35 d-flex">
            <ng-select [items]="authorizations"
                       bindLabel="name"
                       [placeholder]="!selection.installationType ? '' : 'Auswählen...'"
                       [disabled]="!selection.installationType"
                       (change)="onSelectChange('authorizations')"
                       [(ngModel)]="selection.authorization">
            </ng-select>
            <app-tooltip
                    [message]="labelInfos.authorization"
                    [large]="true"
            ></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-60 single-line">
        <div class="row">
          <div class="col-60 col-lg-25 d-flex label-container">
            <label>Abrechnungsfähigkeit</label>
          </div>
          <div class="col-60 col-lg-35 d-flex">
            <ng-select [items]="standardized"
                       bindLabel="name"
                       [placeholder]="!selection.installationType ? '' : 'Auswählen...'"
                       [disabled]="!selection.installationType"
                       (change)="onSelectChange('standardized')"
                       [(ngModel)]="selection.standardized">
            </ng-select>
            <app-tooltip
                    [message]="labelInfos.standardized"
                    [large]="true"
            ></app-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lds-css ng-scope" [hidden]="!loading">
    <div class="wrapper">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p style="text-align: center !important;" [innerHTML]="loadingText"></p>
    </div>
  </div>
  <!--<div class="row">-->
    <!--<div class="col-60 col-md-30">-->
      <!--<div class="row">-->
        <!--<div class="col-60">-->
          <!--<legend class="required required-field-note">Die mit * markierten Felder müssen ausgefüllt werden</legend>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div class="col-md-30 mb-3">-->
      <!--<div class="row">-->
        <!--<div class="col-60 offset-lg-25 col-lg-35">-->
          <!--<button class="btn btn-primary text-uppercase" type="submit" (click)="update()">Produkt auswählen</button>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
</div>
<app-suggestion [selection]="selection"></app-suggestion>
