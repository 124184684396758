<div class="row">
    <div class="col-md-40">
        <h1>Bitte hinterlassen Sie Ihre Kontaktdaten</h1>
    </div>
</div>
<div class="row">
    <div *ngIf="error" class="col-md-40">
        <div class="alert alert-danger">
            {{ error }}
        </div>
    </div>
</div>
<div class="row flex-container">
    <div class="col-md-40">
        <app-contact-form [variant]="variant" [parent]="myself"></app-contact-form>
    </div>
    <div class="col-60 col-md-20" >
        <div *ngIf="variant" class="simple-variant display-product background-color variant">
            <h2>Sie interessieren sich für das Produkt:</h2>
            <div class="img-wrapper">
                <img [src]="getImageUrl()" class="img-fluid" style="max-width: 300px" alt="Produktbild"/>
            </div>
            <div class="product-name">
                <h4>{{ variant.productName }}</h4>
            </div>
            <div class="product-details">
                {{ variant.installationType }}
                / {{ variant.chargerConnections}} x {{ variant.maximumChargingCapacity.toLocaleString() }} kW
            </div>
            <ng-container *ngIf="variant.accessories && variant.accessories.length; then accessoriesContent else accessoriesEmpty"></ng-container>
            <ng-template #accessoriesContent>
                <div class="accessories-list">
                    <h5>Zubehör</h5>
                    <ul>
                        <li *ngFor="let accessory of variant.accessories" class="accessories">
                            {{ accessory.name }}
                        </li>
                    </ul>
                </div>
            </ng-template>
            <ng-template #accessoriesEmpty>
                <h5 class="empty-field">ohne Zubehör</h5>
            </ng-template>

            <ng-container *ngIf="variant.documents && variant.documents.length; then documentsContent else documentsEmpty"></ng-container>
            <ng-template #documentsContent>
                <div class="documents-list">
                    <h5>Dokumente</h5>
                    <ul>
                        <li *ngFor="let document of variant.documents">
                            <a href="{{ document.url }}"
                               target="_blank"
                               class="document-download"
                            >
                                {{ document.description }}
                            </a>
                        </li>
                    </ul>
                </div>
            </ng-template>
            <ng-template #documentsEmpty>
                <h5 class="empty-field">keine Dokumente</h5>
            </ng-template>

            <div class="price">
                <h4>Preis: {{ variant.formatPrice(variant.getTotalPrice()) }}</h4>
            </div>
        </div>
    </div>
</div>
