export class ValueCounter {
    private _maxValue: number|null;
    private _values: object;
    private _maxCount: number;

    private _callbacks = [];


    constructor(maxCount?: number) {
        this._maxCount = maxCount;
        this._values = {};
        this._maxValue = null;
    }

    get maxCount(): number {
        return this._maxCount;
    }

    set maxCount(value: number) {
        this._maxCount = value;
        this.reset();
    }

    addValue(index:number, value: number): void {
        this._values[index] = value;

        this._calculateMaxValue();
    }

    get maxValue(): number|null {
        return this._maxValue;
    }

    addCallback(callback): void {
        this._callbacks.push(callback);
    }

    forceRecalculation(): void {
      this._calculateMaxValue(true);
    }

    private _calculateMaxValue(force: boolean = false): void {
        if (force || Object.keys(this._values).length === this._maxCount) {

            const max = Math.max.apply(null, Object.values(this._values));
            if (max !== 0) {
                this._maxValue = max;
            }

            this._callbacks.forEach((callback) => {
                callback.call(undefined, this.maxValue);
            });
        }
    }

    reset(): void {
        this._values = {}
        this._maxValue = null;
    }
}
