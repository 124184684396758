import {Variant} from "./Variant";

export class ProductCategory {
    productCategoryId: number;
    variants: Variant[];

    constructor(productCategoryId?: number, variants?: Variant[]) {
        this.productCategoryId = productCategoryId;
        this.variants = variants;
    }
}
