import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Variant} from '../../variant-selection/suggestion/Variant';
import {ContactService} from '../contact.service';
import {Contact} from '../contact';
import {ContactResponse} from '../ContactResponse';
import {ContactComponent} from '../contact.component';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {ContactResponseService} from '../contact-response.service';
import {equalityValidator} from '../../equality-validator.directive';
import {PublicUtilityService} from '../public-utility.service';
import {MetaInfoService} from "../../meta-info.service";
import {LastSelectionService} from "../../last-selection.service";
import {SubMandateService} from "../sub-mandate.service";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  static readonly maxCommentLength = 1000;

  get commentMaxLength(): number {
    return ContactFormComponent.maxCommentLength;
  }

  contactForm = new FormGroup({
    /*dropdown*/salutation: new FormControl(null, [
      Validators.required,
    ]),
    companyName: new FormControl(null, [
      Validators.minLength(3),
      Validators.maxLength(255),
    ]),
    forename: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[^0-9]{0,255}$'),
      Validators.minLength(2),
      Validators.maxLength(255),
    ]),
    surname: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[^0-9]{0,255}$'),
      Validators.minLength(2),
      Validators.maxLength(255),
    ]),
    street: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[^0-9]{0,255}$'),
      Validators.minLength(3),
      Validators.maxLength(255),
    ]),
    houseNumber: new FormControl(null, [
      Validators.required,
      Validators.pattern('^\\d+[a-zA-Z]*$'),
      Validators.maxLength(10),
    ]),
    zip: new FormControl(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      Validators.pattern(/[0-9]{5}/),
    ]),
    city: new FormControl(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(255),
    ]),
    email: new FormControl(null, [
      Validators.required,
      Validators.email,
      Validators.maxLength(255),
    ]),
    emailConfirmation: new FormControl(null, [
      Validators.required,
      Validators.email,
      Validators.maxLength(255),
    ]),
    /*dropdown*/contactType: new FormControl(null, [
      Validators.required,
    ]),
    phone: new FormControl(null, [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(30),
      Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'),
    ]),
    installationWanted: new FormControl(null, [
      Validators.required,
    ]),
    comment: new FormControl(null, [
      Validators.maxLength(ContactFormComponent.maxCommentLength),
    ]),
    /*checkbox*/acceptTOS: new FormControl(false, [
      Validators.requiredTrue,
    ]),
    /*hidden*/ productVariantId: new FormControl(null, [
      Validators.required,
    ]),
  }, {
    validators: equalityValidator
  });

  salutationOptions: string[];
  installationWantedOptions: { id: string, name: string }[];
  contactTypes: { id: string, name: string }[];
  publicUtilityOptions: { id: number, name: string, slug: string }[];
  formHidden: boolean;
  spinnerHidden: boolean;
  @Input() variant: Variant;

  @Input() parent: ContactComponent;

  showInstallationWantedHintText: boolean;
  showPhone: boolean;
  showPublicUtility = false;

  error: string;

  constructor(
    private _router: Router,
    private _contactService: ContactService,
    private _contactResponseService: ContactResponseService,
    private _publicUtilityService: PublicUtilityService,
    private _lastSelectionService: LastSelectionService,
    public metaInfoService: MetaInfoService,
  ) {
    this.installationWantedOptions = [
      { id: '1', name: 'Ja' },
      { id: '0', name: 'Nein' }
    ];
    this.formHidden = false;
    this.spinnerHidden = true;
    this.salutationOptions = [
      'Frau',
      'Herr',
      'Keine Angabe'
    ];

    this.contactTypes = [
      {id: 'PHONE', name: 'Telefongespräch'},
      {id: 'ONSITE', name: 'Vor-Ort-Termin mit Begehung'},
      {id: 'EMAIL', name: 'Per E-Mail-Nachricht'}
    ];

    if (!environment.production) {
      this.contactForm.patchValue({
        salutation: 'Herr',
        street: 'Obotritenring',
        forename: 'John',
        surname: 'Doe',
        houseNumber: '35',
        zip: '19053',
        city: 'Schwerin',
        email: 'user@example.com',
        emailConfirmation: 'user@example.com',
        contactType: this.contactTypes[2],
        acceptTOS: true,
      });
    }
  }

  ngOnInit() {
    this.contactForm.patchValue({
      productVariantId: this.variant.variantId
    });

    this.contactForm.get('contactType').statusChanges.subscribe(() => {
      const value = this.contactForm.get('contactType').value; // const instead of let because value is never changed and it's TS :-)
      this.showPhone = (value && value.id === 'PHONE'); // strict comparison ===
    });

    this.installationWanted.statusChanges.subscribe(() => {
      this.showInstallationWantedHintText =
        this.installationWanted.value?.id == 1;
    });

    this.retrievePublicUtilities();
  }

  retrievePublicUtilities(): void {
    this._publicUtilityService.getOptions().subscribe(response => {
      this.publicUtilityOptions = response;
      this.showPublicUtility = this.publicUtilityOptions.length > 0;
      //
      if (this.showPublicUtility) {
        this.contactForm.addControl('publicUtilityId', new FormControl(null, [Validators.required]));
      } else {
        this.contactForm.addControl('publicUtilityId', new FormControl(null, [Validators.nullValidator]));
      }

      if (ContactFormComponent.isDebug()) {
        this.contactForm.patchValue({
          publicUtilityId: this.publicUtilityOptions[0]
        });
      }

      if (PublicUtilityService.publicUtilityFromUrl !== null) {
        const matchedPublicUtility = this.publicUtilityOptions.filter(
          puo => puo.slug.toLowerCase() === PublicUtilityService.publicUtilityFromUrl.toLowerCase()
        );
        if (matchedPublicUtility.length > 0 && matchedPublicUtility.length < 2) {
          this.contactForm.patchValue({
            publicUtilityId: matchedPublicUtility[0]
          });
        }
      }
    }, (error) => console.error(error));
  }

  get contact(): Contact {
    const contact = new Contact();
    Object.assign(contact, this.contactForm.value);

    return contact;
  }

  isValid(): boolean {
    if (!!this.contactForm.errors) {
      return false;
    }
    const invalidControl = Object.keys(this.contactForm.controls).find((key: string): boolean => {
      if (key === 'phone') {
        return (this.contactType.value && this.contactType.value.id === 'PHONE') && !!this.contactForm.get(key).errors;
      }
      return !!this.contactForm.get(key).errors;
    });

    return !invalidControl;
  }

  onSubmit() {
    // when contact form is transmitted to server,
    // set the variant to null to enable automatic redirection to variant selection on navigate back
    this.parent.variantService.variant = null;
    const values = Object.assign({}, this.contactForm.value);
    values.contactType = values.contactType ? values.contactType.id : undefined;
    values.publicUtilityId = values.publicUtilityId ? values.publicUtilityId.id : undefined;
    values.installationWanted = values.installationWanted.id;
    values.subMandateReferral = SubMandateService.referral;
    const contact = new Contact();
    Object.assign(contact, values);

    this.spinnerHidden = false;
    this.formHidden = true;
    this._contactService.sendContact(contact)
      .subscribe((response) => this.handleSubmission(response),
        () => {
          this.parent.error = 'Fehler beim Senden bzw. Verarbeiten des Kontaktformulars. Bitte versuchen Sie es in Kürze erneut.';
          this.spinnerHidden = true;
        });
  }

  handleSubmission(response: ContactResponse) {
    this._contactResponseService.response = response;

    this._router.navigate(['/contract-confirmation']);
  }

  highlightIncompleteFields(): void {
    Object.keys(this.contactForm.controls).forEach((key) => {
      const elem = this.contactForm.get(key);

      if (elem && elem.errors) {
        elem.markAsTouched();
      }
    });
    this.email.markAsTouched();
  }

  getErrorMessage(field: string): string | (() => string) {
    switch (field) {
      case 'acceptTOS':
        return 'Sie müssen die Einwilligung zur Datenverarbeitung geben.';
      case 'city':
        return 'Der Ort muss zwischen 3 und 255 Zeichen lang sein.';
      case 'companyName':
        return 'Der Firmenname muss zwischen 3 und 255 Zeichen lang sein.';
      case 'comment':
        return 'Es sind nur maximal ' + this.commentMaxLength + ' Zeichen erlaubt.';
      case 'email':
      case 'emailConfirmation':
        return 'Die E-Mail-Adressen stimmen nicht überein.';
      case 'forename':
        return 'Der Vorname muss zwischen 2 und 255 Zeichen lang sein.';
      case 'houseNumber':
        return 'Die Hausnummer darf maximal 10 Zeichen lang sein und nur Zahlen und Buchstaben enthalten.';
      case 'phone':
        return 'Es sind nur Ziffern, die Zeichen " ", "-" und "/" erlaubt und Mindestlänge ist 7 Zeichen.';
      case 'street':
        return 'Der Straßenname darf nur aus Buchstaben bestehen und muss zwischen 3 und 255 Zeichen lang sein.';
      case 'surname':
        return 'Der Nachname muss zwischen 2 und 255 Zeichen lang sein.';
      case 'zip':
        return 'Die PLZ muss 5 Zeichen lang sein.';

      default:
        return '';
    }
  }

  getErrorFunction(field: string): () => boolean {
    switch (field) {
      case 'comment':
        return () => {
          const length = this.comment.value ? this.comment.value.length : 0;
          return length > this.commentMaxLength && (this.comment.touched || this.comment.dirty);
        };
      case 'email':
      case 'emailConfirmation':
        return () => {
          return (
            (!!this.contactForm.errors && !!this.contactForm.errors.emailMismatch)
            || !!this.email.errors || !!this.emailConfirmation.errors)
            && (this.contactForm.touched || this.contactForm.dirty);
        };
      default:
        return () => {
          const control = this.contactForm.get(field);
          return (!!control.errors) && (control.touched || control.dirty);
        };
    }
  }

  get remainingChars(): string {
    const value = this.contactForm.get('comment').value;
    const current = value ? value.length : 0;
    return 'Noch ' + Math.max(0, ContactFormComponent.maxCommentLength - current) + ' Zeichen verbleibend';
  }

  get salutation(): AbstractControl {
    return this.contactForm.get('salutation');
  }

  get companyName(): AbstractControl {
    return this.contactForm.get('companyName');
  }

  get forename(): AbstractControl {
    return this.contactForm.get('forename');
  }

  get surname(): AbstractControl {
    return this.contactForm.get('surname');
  }

  get street(): AbstractControl {
    return this.contactForm.get('street');
  }

  get houseNumber(): AbstractControl {
    return this.contactForm.get('houseNumber');
  }

  get zip(): AbstractControl {
    return this.contactForm.get('zip');
  }

  get city(): AbstractControl {
    return this.contactForm.get('city');
  }

  get email(): AbstractControl {
    return this.contactForm.get('email');
  }

  get emailConfirmation(): AbstractControl {
    return this.contactForm.get('emailConfirmation');
  }

  get contactType(): AbstractControl {
    return this.contactForm.get('contactType');
  }

  get phone(): AbstractControl {
    return this.contactForm.get('phone');
  }

  get installationWanted(): AbstractControl {
    return this.contactForm.get('installationWanted');
  }

  get comment(): AbstractControl {
    return this.contactForm.get('comment');
  }

  get acceptTOS(): AbstractControl {
    return this.contactForm.get('acceptTOS');
  }

  get productVariantId(): AbstractControl {
    return this.contactForm.get('productVariantId');
  }

  get publicUtilityId(): AbstractControl {
    return this.contactForm.get('publicUtilityId');
  }

  static isDebug(): boolean {
    return !environment.production;
  }
}
