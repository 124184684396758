import {FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export const equalityValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const email = control.get('email');
  const confirmation = control.get('emailConfirmation');

  return email && confirmation && email.value !== confirmation.value
      ? {'emailMismatch': 'Die Bestätigung der E-Mail-Adresse muss mit der E-Mail-Adresse übereinstimmen'}
      : null;
};
