import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Suggestion} from "./Suggestion";
import {Selection} from "../Selection";
import {IncompleteSelectionException} from "./IncompleteSelectionException";
import {ProductCategory} from "./ProductCategory";
import {Variant} from "./Variant";
import {RequestIdentificationService} from "../request-identification.service";

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  private suggestionUrl = '/api/de/product-variants';

  constructor(
      private http: HttpClient, private requestIdentificationService: RequestIdentificationService
  ) { }

  getSuggestion(selection: Selection, variantId: number = null, download: boolean = false): Observable<Suggestion> {
      if (!selection.isComplete()) {
      throw new IncompleteSelectionException();
    }

    let params = new HttpParams()
      .set('charging_capacity', selection.chargingCapacity.id.toString())
      .set('installation_type', selection.installationType.id.toString())
      .set('charger_connections', selection.chargerConnections.id.toString());
    if (variantId && variantId > 0) {
      params = params.set('variant_id', String(variantId));
    }
    if (download) {
      params = params.set('download_offer', 'true');
    }
    if (selection.authorization) {
        params = params.set('authorization', selection.authorization.id.toString());
    }
    if (selection.standardized) {
        params = params.set('standardized', selection.standardized.id.toString());
    }
    if (selection.backends) {
        selection.backends.forEach(backend => {
            params = params.append('backend[]', backend.id.toString());
        });
    }
    if (selection.accessories) {
        selection.accessories.forEach(accessory => {
            params = params.append('accessory[]', accessory.id.toString());
        });
    }
    if (this.requestIdentificationService.hashIdentifier) {
      params = params.set('hash_identifier', this.requestIdentificationService.hashIdentifier);
    }

    return this.http.get<Suggestion>(this.suggestionUrl, {
      params: params
    })
        .pipe(map((result) => {
          const suggestion = new Suggestion();

          if (!result || result instanceof Array) {
              return suggestion;
          }

          Object.getOwnPropertyNames(result)
          .forEach((productCategoryId) => {
              let category = new ProductCategory(Number(productCategoryId));
              category.variants = [];
              if (result[productCategoryId]) {
                  result[productCategoryId].forEach(obj => {
                      let variant = Object.assign(new Variant(), obj);
                      category.variants.push(variant);
                  });
              }

              suggestion.productCategories.push(category);
          });

          return suggestion;
        }))
      ;
  }
}
