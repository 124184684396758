import {BrowserModule, Title} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {NgSelectConfig, NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertModule } from 'ngx-bootstrap';
import { VariantSelectionComponent } from './variant-selection/variant-selection.component';
import { HttpClientModule } from '@angular/common/http';
import { SuggestionComponent } from './variant-selection/suggestion/suggestion.component';
import { VariantComponent } from './variant-selection/suggestion/variant/variant.component';
import { ContactComponent } from './contact/contact.component';
import { ContactFormComponent } from './contact/contact-form/contact-form.component';
import { ContractConfirmationComponent } from './contract-confirmation/contract-confirmation.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { Selection} from './variant-selection/Selection';
import {MetaInfoService} from "./meta-info.service";
import { ProgressBarComponent } from './progress-bar/progress-bar.component';

export function initializeApp(
    metaInfoService: MetaInfoService,
    titleService: Title,
    ngSelectConfig: NgSelectConfig
) {
    return (): Promise<any> => {
        return metaInfoService.initializeMetaInfoStore().then(function() {
            if (metaInfoService.hasInfo('select_form_no_result')) {
                ngSelectConfig.notFoundText = metaInfoService.getInfoValue('select_form_no_result');
            }
        });
    }
}

@NgModule({
  declarations: [
    AppComponent,
    VariantSelectionComponent,
    SuggestionComponent,
    VariantComponent,
    ContactComponent,
    ContactFormComponent,
    ContractConfirmationComponent,
    TooltipComponent,
    ProgressBarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AlertModule.forRoot(),
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
      {
          provide: APP_INITIALIZER,
          useFactory: initializeApp,
          deps: [MetaInfoService, Title, NgSelectConfig],
          multi: true
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
