import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {RequestIdentification} from "./request-identification";
import {HttpClient} from "@angular/common/http";
import {retry} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RequestIdentificationService {
  url = '/api/de/request-identification/retrieve';
  hashIdentifier: string;
  constructor(private http: HttpClient) { }
  retrieveHashIdentifier(): Observable<RequestIdentification> {
    return this.http.get<RequestIdentification>(this.url).pipe(retry(5));
  }
}
