import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {LabelInfo} from "./LabelInfo";

@Injectable({
  providedIn: 'root'
})
export class LabelInfoService {

  constructor() { }

  getInfo():  Observable<LabelInfo> {
    return of(Object.assign(new LabelInfo(), {
      target_group: 'Zielgruppe',
      charging_capacity: 'Mit wie viel kW soll das Auto geladen werden? Wie schnell soll Ihr Auto geladen werden?',
      charger_connections: 'Wie viele Ladepunkte (mögliche Autos) sollen geladen werden? (eins oder zwei)',
      installation_type: 'Soll die Ladestation an der Wand montiert sein oder als Säule frei stehen?',
      accessories: 'Möchten Sie eine Wunschfarbe, eine zusätzliche Schutzkontaktsteckdose oder Ähnliches?',
      authorization: 'Wie möchten Sie Ihre Ladestation freischalten? ',
      standardized: 'Nur für den Unternehmer und im öffentlichen Sektor nötig. Möchten Sie diesen Strom abrechnen können? Dann JA.',
      backends: 'Anbindung an verschiedene Cloud-Backends',
    }));
  }
}
