import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {SuggestionService} from "./suggestion.service";
import {Suggestion} from "./Suggestion";
import {Selection} from "../Selection";
import {ValueCounterGroup} from "./variant/ValueCounterGroup";
import {LastSelectionService} from "../../last-selection.service";
import {Variant} from "./Variant";
import {ProductCategory} from "./ProductCategory";
import {MetaInfoService} from "../../meta-info.service";

@Component({
    selector: 'app-suggestion',
    templateUrl: './suggestion.component.html',
    styleUrls: ['./suggestion.component.scss']
})
export class SuggestionComponent implements OnInit, AfterViewInit {
    public suggestion: Suggestion;
    @Input() selection: Selection;
    isLoading: boolean;

    constructor(
        private suggestionService: SuggestionService,
        private lastSelectionService: LastSelectionService,
        public metaInfoService: MetaInfoService
    ) {}

    public maxHeight: number = null;
    public rowHeights = [];

    private _valueCounters: ValueCounterGroup;

    @ViewChildren('row') rows: QueryList<ElementRef>;

    get valueCounters(): ValueCounterGroup {
        return this._valueCounters;
    }

    variants: Array<Variant> = [];

    ngOnInit() {
        if (this.lastSelectionService.selection) {
            this.selection = this.lastSelectionService.selection;
        }
        this.selection.callback = () => this.getSuggestions();
        // ValueCounterGroup with as many counters as there are rows in the variant component
        this._valueCounters = new ValueCounterGroup(6);
        this.rowHeights = Array({length: this._valueCounters.length}, () => 'auto');
    }

    getVariantRange(from: number, to: number): Array<Variant> {
        const variantRange = [];
        for(let j = from; j <= to; j++) {
            if(!this.variants[j]) continue;
            variantRange.push(this.variants[j])
        }
        return variantRange;
    }

    ceil(input) {
        const ceil = Math.ceil(input);
        let arr = [];
        for(let current = 0; current < ceil; current++) {
            arr.push(current * 3);
        }
        return arr;
    }

    getSuggestions() {
        if (this.selection.isComplete()) {
            this.lastSelectionService.selection = this.selection;
            this.isLoading = true;
            // set to null to force recreation of app-variant elements (needed for height calculation)
            this.suggestion = null;
            this.suggestionService.getSuggestion(this.selection)
                .subscribe(suggestion => {
                    this.isLoading = false;
                    this.suggestion = suggestion;
                    this.variants = [];
                    let variants = [];
                    suggestion.productCategories.forEach((category: ProductCategory) => {
                        variants.push(...category.variants);
                    });

                    this.variants = variants;
                    this._valueCounters.updateMaxCount(this.variants.length);
                });
        } else {
            this.suggestion = null;
        }
    }

    chunkArray(input: Variant[], count: number): Array<Array<Variant>> {
        if (!input) {
            return [];
        }
        const r = [];
        for (let i = 0; i < input.length; i += count) {
            r.push(input.slice(i, i + count));
        }
        return r;
    }

    ngAfterViewInit(): void {
        for (let i = 0; i < this._valueCounters.length; i++) {
            this._valueCounters.get(i).addCallback(value => {
                this.rowHeights[i] = value + 'px';
            });
        }
    }
}
