<div *ngIf="variants && variants.length > 0" class="w-100 compare compare-background p-3">
    <div class="row" *ngFor="let i of ceil(variants.length / 3)">
            <div class="d-none d-md-flex col-md-15 col-lg-12 compare-column">
                <div class="row">
                    <div class="col-md-60 p-1 spacer"></div>
                    <div #row class="col-md-60" [style.height]="rowHeights[0]"></div>
                    <div #row class="col-md-60 charging-row" [style.height]="rowHeights[1]">
                        Max. Ladeleistung
                    </div>
                    <div #row class="col-md-60" [style.height]="rowHeights[2]">
                        Zubehör
                    </div>
                    <div #row class="col-md-60" [style.height]="rowHeights[3]">
                        Weitere Produktinformationen
                    </div>
                    <div #row class="col-md-60 price-row" [style.height]="rowHeights[4]">
                        Angebotspreis
                    </div>
                    <div #row class="col-md-60" [style.height]="rowHeights[5]">
                    </div>
                </div>
            </div>
            <div *ngFor="let variant of getVariantRange(i, i + 2); let colIndex = index;" class="col-60 col-md-15 col-lg-12 compare-column">
                <app-variant
                    [colIndex]="i + colIndex"
                    [variant]="variant"
                    [maxHeights]="valueCounters"
                    [parent]="this">
                </app-variant>
            </div>
    </div>
</div>
<div *ngIf="variants.length < 1">
    <alert type="info" *ngIf="selection.isComplete() && !isLoading">
        Keine Produkte gefunden.
    </alert>
    <alert type="info" *ngIf="selection.isComplete() && isLoading">
        Produkte werden geladen...
    </alert>
    <alert type="info" *ngIf="!selection.isComplete()">
        Bitte geben Sie mindestens die folgenden Informationen an, um Produktvorschläge zu erhalten:
        <ul>
            <li>Ladeleistung</li>
            <li>Anzahl der Ladepunkte</li>
            <li>Installationsart</li>
        </ul>
    </alert>
</div>

