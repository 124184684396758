import { Injectable } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PublicUtilityResponse} from './PublicUtilityResponse';
import {retry} from 'rxjs/operators';
import {PublicUtility} from './PublicUtility';

@Injectable({
  providedIn: 'root'
})
export class SubMandateService {
  public static referral: string|null;
  readonly MAX_RETRIES = 3;
  constructor(private http: HttpClient) {
  }
}
