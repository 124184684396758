export class VariantRelation {
    name: string;
    price: number;

    constructor(name?: string, price?: number) {
        this.name = name;
        this.price = price;
    }
}

export class ProductBackend extends VariantRelation {}

export class Accessory extends VariantRelation {
  id: number;

  constructor(name?: string, price?: number, id?: number) {
    super(name, price);
    this.id = id;
  }

}
