import {
  Accessory,
  Authorization,
  Backend, ChargerConnection,
  ChargingCapacity,
  InstallationType,
  Standardized,
  TargetGroup
} from './DataOption';

export class Selection {
    private _chargingCapacity: ChargingCapacity;
    private _installationType: InstallationType;
    private _authorization: Authorization;
    private _backends: Backend[] = [];
    private _accessories: Accessory[] = [];
    private _chargerConnections: ChargerConnection;
    private _standardized: Standardized;
    private _callback;

    set callback(callback) {
        this._callback = callback;
    }

    get callback() {
        return this._callback;
    }

    get chargingCapacity(): ChargingCapacity {
        return this._chargingCapacity;
    }

    set chargingCapacity(value: ChargingCapacity) {
        this._chargingCapacity = value;
        this.onChange();
    }

    get installationType(): InstallationType {
        return this._installationType;
    }

    set installationType(value: InstallationType) {
        this._installationType = value;
        this.onChange();
    }

    get authorization(): Authorization {
        return this._authorization;
    }

    set authorization(value: Authorization) {
        this._authorization = value;
        this.onChange();
    }

    get backends(): Backend[] {
        return this._backends;
    }

    set backends(value: Backend[]) {
        this._backends = value;
        this.onChange();
    }

    get accessories(): Accessory[] {
        return this._accessories;
    }

    set accessories(value: Accessory[]) {
        this._accessories = value;
        this.onChange();
    }

    get standardized(): Standardized {
        return this._standardized;
    }

    set standardized(value: Standardized) {
        this._standardized = value;
        this.onChange();
    }

    get chargerConnections(): ChargerConnection {
      return this._chargerConnections;
    }

    set chargerConnections(value: ChargerConnection) {
      this._chargerConnections = value;
      this.onChange();
    }

    onChange() {
        if (this._callback) {
            this._callback.call();
        }
    }

    isComplete(): boolean {
        return this.installationType != null
          && this.chargerConnections != null
          && this.chargingCapacity != null;
    }
}
