import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PublicUtilityResponse} from './PublicUtilityResponse';
import {retry} from 'rxjs/operators';
import {PublicUtility} from './PublicUtility';

@Injectable({
  providedIn: 'root'
})
export class PublicUtilityService {
  public static publicUtilityFromUrl: string|null;
  private apiUrl = '/api/de/public-utility';
  readonly MAX_RETRIES = 3;
  constructor(private http: HttpClient) {
  }
  getOptions(): Observable<PublicUtility[]> {
    return this.http.get<PublicUtility[]>(this.apiUrl)
      .pipe(
        retry(this.MAX_RETRIES)
      );
  }
}
