import {Variant} from "./suggestion/Variant";
import {Selection} from "./Selection";
import {Accessory} from "./DataOption";
import * as _ from "underscore";

export class VariantFilter {
    installationTypeId: number;
    chargingCapacityId: number;
    chargerConnections: number;
    accessories: number[];
    authorization: string;
    standardized: boolean;

    public static fromSelection(selection: Selection, fields: string[] = null): VariantFilter {
        if (!fields) {
            fields = this.allFields;
        }

        const filter = new VariantFilter();
        if (selection.chargingCapacity && _.includes(fields, 'chargingCapacity')) {
            filter.chargingCapacityId = parseFloat(selection.chargingCapacity.name.replace(',', '.'));
        }
        if (selection.chargerConnections && _.includes(fields, 'chargerConnections')) {
            filter.chargerConnections = selection.chargerConnections.id;
        }
        if (selection.installationType && _.includes(fields, 'installationType')) {
            filter.installationTypeId = selection.installationType.id;
        }
        if (selection.accessories && selection.accessories.length && _.includes(fields, 'accessories')) {
            filter.accessories = [];
            selection.accessories.forEach(
                (a: Accessory) => filter.accessories.push(a.id)
            );
        }
        if (selection.authorization && _.includes(fields, 'authorization')) {
            filter.authorization = selection.authorization.name;
        }
        if (selection.standardized && _.includes(fields, 'standardized')) {
            filter.standardized = !!selection.standardized.id;
        }
        return filter;
    }

    public static get allFields(): string[] {
        return [
            'chargingCapacity', 'chargerConnections', 'installationType',
            'accessories', 'authorization', 'standardized'
        ];
    }

    public match(variant: Variant): boolean {
        let match = true;
        if (this.installationTypeId) {
            match = match && this.installationTypeId === variant.installationTypeId;
        }
        if (this.chargingCapacityId) {
            match = match &&  _.includes(variant.chargingCapacities, this.chargingCapacityId);
        }
        if (this.chargerConnections) {
            match = match && this.chargerConnections === variant.chargerConnections;
        }
        if (this.accessories) {
            match = match && _.difference(this.accessories, _.map(variant.accessories, v => v.id)).length === 0;
        }
        if (this.authorization) {
            match = match && _.includes(variant.chargingAuthorization, this.authorization);
        }
        if (this.standardized) {
            match = match && this.standardized === variant.standardized;
        }

        return match;
    }
}
