import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetaInfoService {
  private metaInfoStore = {};

  readonly MAX_RETRIES = 3;

  constructor(private http: HttpClient) {
  }

  private apiUrl = '/api/de/meta-info';

  async initializeMetaInfoStore(referral: string = '') {
    this.metaInfoStore = await this.http.get(
        this.apiUrl, {params: new HttpParams().set('referral', referral)}
    ).pipe(
        retry(this.MAX_RETRIES)
    ).toPromise()
  }

  hasInfo(infoKey: string): boolean {
    return this.metaInfoStore.hasOwnProperty(infoKey) && this.metaInfoStore[infoKey] !== '';
  }

  getInfoValue(infoKey: string): string {
    if (this.hasInfo(infoKey)) {
      return this.metaInfoStore[infoKey];
    } else {
      return null;
    }
  }
}
