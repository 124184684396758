<h1>Vielen Dank für Ihr Interesse</h1>
<div class="row">
  <p class="col-60 col-sm-45">
    Wir haben Ihre Anfrage erhalten und werden diese umgehend bearbeiten.
    Über den von Ihnen ausgewählten Kontaktweg <span *ngIf="contactResponse.contactType">"{{ contactResponse.contactType }}"</span> wird sich ein Fachberater von dem ausgewählten emevo-Mitglied bei Ihnen melden und Sie über die Stromladesäulen-Produkte des Unternehmens weiter informieren.
  </p>
</div>
<div class="row">
  <p class="col-60 col-sm-45">
    Für weitere Informationen besuchen Sie bitte auch folgende Seite:
  </p>
</div>
<form>
  <div class="row">
    <div class="col-60 col-sm-15">
      <button [hidden]="!this.metaInfoService.hasInfo('more_information_button_href')" (click)="clickButton()" class="btn btn-primary">
          {{ this.metaInfoService.getInfoValue('more_information_button_text') }}
      </button>
    </div>
  </div>
</form>
