import * as _ from 'underscore';
import {Variant} from './suggestion/Variant';
import {VariantFilter} from "./VariantFilter";

export class VariantRepository {
  private readonly allVariants: Variant[] = [];

  constructor(variants: Variant[]) {
    this.allVariants = variants || [];
  }

  public getAllVariants(): Variant[] {
    return this.allVariants;
  }

  public get(field: string, filter: VariantFilter): (string|number)[] {
    const filtered = this.allVariants.filter((v) => {
      return filter.match(v);
    });
    const ids = [];
    filtered.forEach((variant: Variant) => {
      const value = variant[field];
      if (value instanceof Array) {
        value.forEach((v) => {
          if (_.has(v, 'id')) {
            ids.push(v.id);
          } else {
            ids.push(v);
          }
        });
      } else {
        ids.push(value);
      }
    });

    return _.uniq(ids);
  }
}
