import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {VariantSelectionComponent} from "./variant-selection/variant-selection.component";
import {ContactComponent} from "./contact/contact.component";
import {ContractConfirmationComponent} from "./contract-confirmation/contract-confirmation.component";

const routes: Routes = [
  { path: '', component: VariantSelectionComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'contract-confirmation', component: ContractConfirmationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
