export class Contact {
    salutation: string;
    companyName: string;
    forename: string;
    surname: string;
    street: string;
    houseNumber: string;
    zip: string;
    city: string;
    email: string;
    emailConfirmation: string;
    contactType: string;
    phone: string;
    comment: string;
    acceptTOS: boolean;
    publicUtilityId: number;
    subMandateReferral: string | null;
    productVariantId: number;
    hashIdentifier: string | null;
    isComplete(): boolean {
        return !(!this.salutation
        || !this.street
        || !this.zip
        || !this.city
        || !this.email
        || !this.emailConfirmation
        || (this.email !== this.emailConfirmation)
        || !this.contactType
        || (this.contactType === 'PHONE' && !this.phone)
        || !this.acceptTOS
        || !this.productVariantId);

    }
}
