import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  private _cssClasses = 'ls-tooltip-container';
  get cssClasses(): string {
    return this._cssClasses
        + ' ' + (this.classes ? this.classes : '')
        + ' ' + (this.hovered ? 'hover' : '')
        + ' ' + (this.large ? 'tooltip-large' : '')
        ;
  }

  hovered = false;

  @Input() message: string|(() => string);
  @Input() showIf: () => boolean;
  @Input() type: string;
  @Input() classes: string;
  @Input() large: boolean;
  @Input() iconClass: string;

  get typeCssClass(): string {
    return this.type + '-tooltip ls-tooltip';
  }

  constructor() { }

  ngOnInit() {
    this.type = 'info';
  }

  showToolTip(): boolean {
    return this.showIf ? this.showIf() : !!this.messageText;
  }

  get messageText(): string {
    if (this.message instanceof Function) {
      return this.message();
    } else {
      return this.message || '';
    }
  }

  get icon(): string {
    if (this.iconClass == null) {
        return 'fa-info-circle';
    } else {
        return this.iconClass;
    }
  }
}
