import {ValueCounter} from "../ValueCounter";

export class ValueCounterGroup {
    private readonly _counters: ValueCounter[];

    constructor(size: number) {
        this._counters = Array.from({length: size}, () => new ValueCounter());
    }

    get length() {
        return this._counters.length;
    }

    updateMaxCount(value): void {
        this._counters.forEach(counter => {
            counter.maxCount = value;
        });
    }

    add(index: number, colIndex: number, value): void {
        this._counters[index].addValue(colIndex, value);
    }

    get(index: number): ValueCounter {
        return this._counters[index];
    }

    forceRecalculation(): void {
      this._counters.forEach(counter => counter.forceRecalculation());
    }
}
