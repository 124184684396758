import {Variant} from './suggestion/Variant';

export class DataOption {
    id: number;
    name: string;

    constructor(id?: number, name?: string) {
        this.id = id;
        this.name = name;
    }
}

export class Authorization extends DataOption {}

export class ChargingCapacity extends DataOption {}

export class InstallationType extends DataOption {}

export class TargetGroup extends DataOption {}

export class Backend extends DataOption  {}

export class Accessory extends DataOption {}

export class Standardized extends DataOption {}

export class ChargerConnection extends DataOption {}

export class DataOptionGrouping {
    authorizations: Authorization[];
    chargingCapacities: ChargingCapacity[];
    installationTypes: InstallationType[];
    targetGroups: TargetGroup[];
    backends: Backend[];
    accessories: Accessory[];
    variants: Variant[];
    chargerConnections: ChargerConnection[];
}
