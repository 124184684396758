import {Injectable} from '@angular/core';
import {DataOptionGrouping} from './DataOption';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataOptionsService {
  private targetGroupUrl = '/api/de/data-options/options';
  readonly MAX_TRIES = 3;

  constructor(
      private http: HttpClient
  ) { }

  getOptions(): Observable<DataOptionGrouping> {
    return this.http.get<DataOptionGrouping>(this.targetGroupUrl)
        .pipe(
            retry(this.MAX_TRIES)
        );
  }
}
