import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ErrorObserver, Observable, throwError} from "rxjs";
import {Contact} from "./contact";
import {IncompleteContactException} from "./IncompleteContactException";
import {catchError} from "rxjs/operators";
import {ContactResponse} from "./ContactResponse";
import {RequestIdentificationService} from "../variant-selection/request-identification.service";

@Injectable({
  providedIn: 'root'
})

export class ContactService {
  private targetUrl = '/api/de/contact';

  constructor(
      private http: HttpClient, private requestIdentificationService: RequestIdentificationService
  ) { }

  static handleError(error: HttpErrorResponse|any) {
    if (error.hasOwnProperty('error') && (error.error instanceof ErrorEvent)) {
      return throwError('Die Kontaktanfrage konnte nicht gesendet werden.');
    } else if (error.hasOwnProperty('error')) {
      return throwError(error.error.message);
    }
  }

  sendContact(contact: Contact): Observable<ContactResponse> {
    if (!contact.isComplete()) {
      throw new IncompleteContactException();
    }
    if (this.requestIdentificationService.hashIdentifier) {
      contact.hashIdentifier = this.requestIdentificationService.hashIdentifier;
    }
    return this.http.post<ContactResponse>(this.targetUrl, contact);
  }
}
