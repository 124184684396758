import { Injectable } from '@angular/core';
import { Selection } from "./variant-selection/Selection";

@Injectable({
  providedIn: 'root'
})
export class FinalSelectionService {
  public selection: Selection;
  constructor() { }
}
