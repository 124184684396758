import { Component, OnInit } from '@angular/core';
import {VariantToFormServiceService} from "../variant-to-form-service.service";
import {Router} from "@angular/router";
import {Variant} from "../variant-selection/suggestion/Variant";
import {MetaInfoService} from "../meta-info.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  error: string;
  myself = this;

  constructor(
      public variantService: VariantToFormServiceService,
      router: Router,
      public metaInfoService: MetaInfoService
  ) {
    if (!variantService.variant) {
      router.navigate(['/']);
    }
  }

  ngOnInit() {

  }

  getImageUrl(): string {
    if (this.variantService.variant.images[0]) {
      return this.variantService.variant.images[0].url;
    } else {
      return 'assets/img/product-' + this.variantService.variant.productCategoryId + '.png';
    }
  }

  get variant(): Variant {
    return this.variantService.variant;
  }
}
