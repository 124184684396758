import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ContactResponseService} from '../contact/contact-response.service';
import {ContactResponse} from '../contact/ContactResponse';
import {ContactComponent} from '../contact/contact.component';
import {MetaInfoService} from '../meta-info.service';
import {VariantSelectionComponent} from "../variant-selection/variant-selection.component";
import {SuggestionService} from "../variant-selection/suggestion/suggestion.service";
import {VariantComponent} from "../variant-selection/suggestion/variant/variant.component";
import {FinalSelectionService} from "../final-selection.service";
import {VariantToFormServiceService} from "../variant-to-form-service.service";

@Component({
  selector: 'app-contract-confirmation',
  templateUrl: './contract-confirmation.component.html',
  styleUrls: ['./contract-confirmation.component.scss']
})
export class ContractConfirmationComponent implements OnInit {
  private static readonly contactDataStoreKey = 'contactData';

  constructor(
      protected _router: Router,
      protected _contactResponseService: ContactResponseService,
      private _suggestionService: SuggestionService,
      private _finalSelectionService: FinalSelectionService,
      private _formService: VariantToFormServiceService,
      public metaInfoService: MetaInfoService

  ) {
    if (!_contactResponseService.response) {
      _router.navigate(['/']);
    }
  }

  ngOnInit() {
      if (window.sessionStorage) {
          window.sessionStorage.removeItem(ContractConfirmationComponent.contactDataStoreKey);
      }
  }

  get contactResponse(): ContactResponse {
    return this._contactResponseService.response;
  }

  public clickButton() {
    const url = this.metaInfoService.getInfoValue('more_information_button_href');
    window.open(url, '_blank');
  }

  public downloadOffer() {
    this._suggestionService.getSuggestion(this._finalSelectionService.selection, this._formService.variant.variantId, true)
      .subscribe(resp => {});

    const url = this.contactResponse.downloadActionUrl;
    window.open(url, '_blank');
  }
}
